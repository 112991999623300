import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import * as authActions from "../../redux/actions/authActions";
import {
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";

import { MuiOtpInput } from "mui-one-time-password-input";

const OTPVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = React.useState("");
  const { passwordResetRequestData } = useSelector(
    (state) => state.authentication
  );

  console.log("dartatatat", passwordResetRequestData);
  const handleChange = (newValue) => {
    setOtp(newValue);
    console.log("OTP", newValue);
  };

  const onSubmitHandler = (e) => {
    const data = {
      id: passwordResetRequestData?._id,
      code: otp,
    };
    if (otp) {
      dispatch(authActions.OTPVerificationRequest(data, callBack));
    } else {
      toastify("error", "Kindly enter the OTP");
    }
    e.preventDefault();
  };
  const callBack = (e) => {
    if (e.status) {
      navigate("/resetpassword");
    } else {
      toastify("error", e.response.data.message);
    }
  };
  const handleResendCode = () => {
    dispatch(
      authActions.resendOTPRequest(passwordResetRequestData?._id, callBackForResendCode)
    );
  };
  const callBackForResendCode=(e)=>{
console.log(e.response)
  }
  return (
    <>
      <ToastContainer />
      <div className="bg-gray-300 min-h-screen flex items-center justify-center rounded">
        <>
          <form
            className="bg-white p-8 rounded-2xl border border-gray-300 w-[500px]"
            onSubmit={onSubmitHandler}
            autoComplete="off"
          >
            <div className="text-center mb-4">
              <h3 className="text-4xl font-bold mb-4">OTP Verification</h3>
              <Typography className="text-gray-500 text-base px-6">
                We sent an OTP to your email
              </Typography>
            </div>

            <div className="my-2">
              <div className="my-4">
                <MuiOtpInput length={5} value={otp} onChange={handleChange} />
              </div>

              <Typography
                className="text-right text-[#0360B2] underline"
                sx={{
                  fontWeight: "bold !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/forgetpassword");
                }}
              >
                Want to enter to email again?
              </Typography>

              <Typography
                className=" text-right text-[#0360B2] underline"
                sx={{
                  fontWeight: "bold !important",
                  cursor: "pointer",
                }}
                onClick={handleResendCode}
              >
                Resend code
              </Typography>
            </div>
            <div className="flex justify-end">
              {loading ? (
                <Button variant="contained" disabled>
                  <CircularProgress size={24} thickness={5} />
                </Button>
              ) : (
                <Button type="submit" variant="contained">
                  Verify
                </Button>
              )}
            </div>
          </form>
        </>
      </div>
    </>
  );
};

export default OTPVerification;
