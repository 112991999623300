export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const RESEND_OTP = "RESEND_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";

// manage users
export const GET_ALL_USERS = "GET_ALL_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "GET_ALL_USERS";

// manage content
export const GET_ALL_CONTENTS = "GET_ALL_CONTENTS";
export const CREATE_CONTENT = "CREATE_CONTENT";
export const EDIT_CONTENT = "EDIT_CONTENT";
export const DELETE_CONTENT = "GET_ALL_CONTENTS";

// manage interest
export const GET_ALL_INTERESTS = "GET_ALL_INTERESTS";
export const CREATE_INTEREST = "CREATE_INTEREST";
export const EDIT_INTEREST = "EDIT_INTEREST";
export const DELETE_INTEREST = "GET_ALL_INTERESTS";

// manage habits
export const GET_ALL_HABITS = "GET_ALL_HABITS";
export const CREATE_HABIT = "CREATE_HABIT";
export const EDIT_HABIT = "EDIT_HABIT";
export const DELETE_HABIT = "GET_ALL_HABITS";

// dashboard
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
