import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import * as authActions from "../../redux/actions/authActions";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  var values = {
    email: email,
    password: password,
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      dispatch(authActions.userLoginRequest(values, callbackForLogin));
    } else {
      toastify("error", "Please fill all fields");
    }
  };

  const callbackForLogin = (e) => {
    setLoading(false);
    console.log(e);

    if (e.data?.role === 4) {
      navigate("/dashboard");
    } else {
      toastify("error", e.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-gray-300 min-h-screen flex items-center justify-center rounded">
        <>
          <form
            className="bg-white p-8 rounded-2xl border border-gray-300 w-[500px]"
            onSubmit={onSubmitHandler}
            autoComplete="off"
          >
            <div className="text-center mb-4">
              <h3 className="text-4xl font-bold mb-4">Login</h3>
              <Typography className="text-gray-500 text-base px-6">
                Welcome to the super admin
              </Typography>
            </div>

            <div className="my-4">
              <div className="mb-4">
                <FormControl className="w-full">
                  <TextField
                    type="email"
                    value={email}
                    size="small"
                    placeholder="Email*"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </div>

              <div className="mb-4">
                <FormControl className="w-full">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={password}
                    size="small"
                    placeholder="Password*"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>

              <Typography
                className="text-right text-[#0360B2] "
                sx={{
                  fontWeight: "bold !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/forgetpassword");
                }}
              >
                Forget password?
              </Typography>
            </div>

            <div className="flex justify-end">
              {loading ? (
                <Button className="w-10" variant="contained" disabled>
                  <CircularProgress size={24} thickness={5} />
                </Button>
              ) : (
                <Button className="w-5" type="submit" variant="contained">
                  Login
                </Button>
              )}
            </div>
          </form>
        </>
      </div>
    </>
  );
};

export default Login;
