import * as actionTypes from "../actions/actionType";

const initialState = {
  allUsers: [],
};

export const manageUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case actionTypes.CREATE_USER:
      return {
        ...state,
        newUser: action.payload,
      };

    default:
      return state;
  }
};
