import * as actionTypes from "../actions/actionType";

const initialState = {
  user: null,
  token: null,
  passwordResetRequestData: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };

    case actionTypes.FORGET_PASSWORD:
      return {
        ...state,
        passwordResetRequestData: action.payload,
      };
    default:
      return state;
  }
};
