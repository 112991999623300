import React from "react";

const Navbar = () => {
  return (
    <>
      <div className="nav-top py-2 px-3 navbar-main-container bg-slate-300 ">
        <div className="row ">
          <div className="col-md-6 d-flex ">
            <h4 className="font-weight-bold mt-3 navbar-welcome-name text-2xl ml-4">
              Welcome Admin
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
