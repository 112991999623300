const authURLs = {
  // authentication
  login: "/users/auth/login",
  otpSend: "/otp/send",
  otpVerify: "/otp/verify",
  forget_password: "/users/auth/forgot-password",
  otp_verification: "/users/auth/verify-code",
  resendOTPUrl: "/users/auth/verify-code/",
  resetPasswordUrl: "/users/auth/reset-password",
  //dashboard
  getDashboardDataUrl: "/users/super-admin/dashboard",
  // User
  getAllUsersUrl: "/super-admin/users",
  createUserUrl: "/super-admin/users",
  editUserUrl: "/super-admin/users/",
  deleteUserUrl: "/super-admin/users/",
  //Interest
  getAllInterestsUrl: "/interests/listing",
  createInterestUrl: "/interests/create",
  editInterestUrl: "/interests/edit/",
  deleteInterestUrl: "/interests/remove/",
  //Habit
  getAllHabitsUrl: "/habits/listing",
  createHabitUrl: "/habits/create",
  editHabitUrl: "/habits/edit/",
  deleteHabitUrl: "/habits/remove/",
  //Content
  getAllContentsUrl: "/medias/super-admin/listing",
  createContentUrl: "/medias/create",
  editContentUrl: "/medias/edit/",
  deleteContentUrl: "/medias/remove/",
};

export default authURLs;
