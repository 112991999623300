import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Modal,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createContentRequest,
  deleteContentRequest,
  editContentRequest,
  getAllContents,
  getAllInterests,
} from "../../redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import AWS from "aws-sdk";
const ManageContents = () => {
  const dispatch = useDispatch();
  const { allContents } = useSelector((state) => state.allContentData);
  const { allInterest } = useSelector((state) => state.allInterestData);
  useEffect(() => {
    dispatch(getAllContents());
    dispatch(getAllInterests());
  }, []);
  console.log("asasas", allInterest);

  const [selectedContent, setSelectedContent] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [createContentDetails, setCreateContentDetails] = useState({
    name: "",
    description: "",
    interest: "",
    frequency: "",
  });
  const [editedContentDetails, setEditedContentDetails] = useState({
    name: "",
    description: "",
    interest: "",
    frequency: "",
  });
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [s3VideoUrl, setS3VideoUrl] = useState(false);
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = (Content) => {
    setMenuAnchor(null);
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setSelectedContent(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedContent(null);
    setEditedContentDetails(null);
  };
  const handleMenuOpen = (event, Content) => {
    setMenuAnchor(event.currentTarget);
    setSelectedContent(Content);
    console.log("selected", Content);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateContentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCreateContent = () => {
    if (
      createContentDetails?.name &&
      createContentDetails?.description &&
      createContentDetails?.interest &&
      createContentDetails?.type &&
      file
    ) {
      const formData = new FormData();
      formData.append("name", createContentDetails.name);
      formData.append("description", createContentDetails.description);
      formData.append("interest_id", createContentDetails.interest);
      formData.append("media_url", file);
      formData.append("type", createContentDetails?.type);

      dispatch(createContentRequest(formData, callBackForCreateContent));
    } else {
      toastify("error", "Kindly fill all the fields");
      setLoading(false);
    }
  };
  const callBackForCreateContent = (e) => {
    if (e.status) {
      dispatch(getAllContents());
      setCreateContentDetails({});
      setSelectedContent(null);
      handleCloseCreateModal();
      setS3VideoUrl(false);
      setLoading(false);
    } else {
      toastify("error", e.response.data.message);
      setLoading(false);
    }
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    setEditedContentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleUpdateContent = () => {
    console.log("adad", editedContentDetails);
    console.log("adad", selectedContent);

    if (editedContentDetails) {
      const data = {
        name: editedContentDetails?.name
          ? editedContentDetails?.name
          : selectedContent?.name,
        description: editedContentDetails?.description
          ? editedContentDetails?.description
          : selectedContent?.description,
        frequency: editedContentDetails?.frequency
          ? editedContentDetails?.frequency
          : selectedContent?.frequency,
        interest_id: editedContentDetails?.interest
          ? editedContentDetails?.interest
          : selectedContent?.interest_id,
      };
      dispatch(
        editContentRequest(selectedContent?._id, data, callBackForDeleteContent)
      );
    } else {
      toastify("error", "Kindly fill all the fields");
    }
  };

  const handleDeleteClick = (Content) => {
    setMenuAnchor(null);
    dispatch(
      deleteContentRequest(selectedContent._id, callBackForDeleteContent)
    );
  };
  const callBackForDeleteContent = () => {
    setOpenEditModal(false);

    dispatch(getAllContents());
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("fileee", selectedFile);
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!file) {
      toastify("error", "Kindly select a file");
      setLoading(false);
      return;
    }

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const params = {
      Bucket: "ahhaa-app-s3-bucket/local",
      Key: `${file.name}`,
      Body: file,
      ContentType: file.type,
    };
    if (!s3VideoUrl) {
      try {
        const response = await s3.upload(params).promise();
        console.log("File uploaded successfully:", response.Location);

        handleCreateContent(response.Location);
        setS3VideoUrl(true);
      } catch (error) {
        console.error("Error uploading file to S3:", error);
      }
    } else {
      handleCreateContent();
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        aria-labelledby="modal-create-title"
        aria-describedby="modal-create-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h3>Create Content</h3>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={createContentDetails.name}
              onChange={handleInputChange}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              value={createContentDetails.email}
              onChange={handleInputChange}
            />
            <TextField
              label="Type"
              variant="outlined"
              fullWidth
              margin="normal"
              name="type"
              value={createContentDetails.type}
              onChange={handleInputChange}
            />
            <Typography className="text-neutral-500">
              Upload Audio/Video
            </Typography>
            <TextField
              sx={{ marginTop: "0px" }}
              variant="outlined"
              type="file"
              fullWidth
              margin="normal"
              name="uploadFile"
              onChange={handleFileChange}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="interest-id-label">Interest </InputLabel>
              <Select
                labelId="interest-id-label"
                id="interest-id"
                name="interest"
                value={createContentDetails.interest}
                onChange={handleInputChange}
                label="Interest ID"
              >
                {allInterest?.data?.map((interest) => (
                  <MenuItem key={interest._id} value={interest._id}>
                    {interest.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleUpload}>
              {loading ? "Uploading..." : "Create"}
            </Button>
            <Button onClick={handleCloseCreateModal}>Cancel</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-edit-title"
        aria-describedby="modal-edit-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Edit Content</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              defaultValue={selectedContent?.name}
              onChange={(e) => handleEditInputChange(e)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              defaultValue={selectedContent?.description}
              onChange={(e) => handleEditInputChange(e)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="interest-id-label">Interest </InputLabel>
              <Select
                labelId="interest-id-label"
                id="interest-id"
                name="interest"
                defaultValue={selectedContent?.interest}
                onChange={handleInputChange}
                label="Interest ID"
              >
                {allInterest?.data?.map((interest) => (
                  <MenuItem key={interest._id} value={interest._id}>
                    {interest.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Type"
              variant="outlined"
              fullWidth
              margin="normal"
              name="type"
              defaultValue={selectedContent?.frequency}
              onChange={(e) => handleEditInputChange(e)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateContent}
            >
              Update
            </Button>
            <Button onClick={handleCloseEditModal}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <div className="flex justify-between items-center px-10 mt-3 mb-5">
        <Typography className="font-bold" style={{ fontSize: "2rem" }}>
          Manage Contents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateModal}
        >
          Add Content
        </Button>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "90%",
          margin: "auto",
          overflowY: "auto",
          maxHeight: "600px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: "10",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allContents?.data?.map((Content) => (
              <TableRow key={Content._id}>
                <TableCell component="th" scope="row">
                  {Content.name}
                </TableCell>
                <TableCell align="center">
                  {Content.type === 1 ? "audio" : "video"}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, Content)}
                    size="small"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOpenEditModal(Content)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteClick(Content)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ManageContents;
