import * as actionTypes from "./actionType";
import * as services from "../../services";
import authURL from "../../constants/authURL";
import { toastify } from "../../components/Toast";

//   user login
export const userLoginRequest = (values, callback) => (dispatch) => {
  services
    .makePostRequest(authURL.login, values)
    .then((response) => {
      dispatch({ type: actionTypes.USER_LOGIN, payload: response.data });
      if (response.status) {
        callback(response);
      }
    })
    .catch((error) => {
      callback(error);
    });
};
// Forget password
export const forgetPasswordRequest = (email, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.forget_password, email)
    .then((response) => {
      dispatch({ type: actionTypes.FORGET_PASSWORD, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};

// OTP verification
export const OTPVerificationRequest = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.otp_verification, data)
    .then((response) => {
      dispatch({ type: actionTypes.OTP_VERIFICATION, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};
//Resend OTP
export const resendOTPRequest = (id, callback) => (dispatch) => {
  services
    .makeGetRequest(authURL.resendOTPUrl + `${id}`)
    .then((response) => {
      dispatch({
        type: actionTypes.RESEND_OTP,
        payload: response?.data,
      });

      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

//Reset password
export const resetPasswordRequest = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.resetPasswordUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.RESET_PASSWORD, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      toastify("error", error.response.data.message);
    });
};

export const removeToken = () => {
  return {
    type: actionTypes.USER_LOGOUT,
  };
};

//Dashboard data
export const getDashboardDataRequest = (callback) => (dispatch) => {
  services
    .makeGetRequest(authURL.getDashboardDataUrl)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_DASHBOARD_DATA,
        payload: response?.data,
      });

      if (response.status === 200) {
        callback(response);
      }
    })
    .catch((error) => {
      callback(error);
    });
};
// get all users
export const getAllUsers = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllUsersUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_USERS, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
//Create user
export const createUserRequest = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.createUserUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.CREATE_USER, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};
//Edit user
export const editUserRequest = (id, data, callBack) => (dispatch) => {
  services
    .makePatchRequest(authURL.editUserUrl + `${id}`, data)
    .then((response) => {
      dispatch({ type: actionTypes.EDIT_USER, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
//Delete user
export const deleteUserRequest = (id, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteUserUrl + `${id}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_USER, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};

// get all Interests
export const getAllInterests = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllInterestsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_INTERESTS, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
//Create Interest
export const createInterestRequest = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.createInterestUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.CREATE_INTEREST, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};
//Edit Interest
export const editInterestRequest = (id, data, callBack) => (dispatch) => {
  services
    .makePatchRequest(authURL.editInterestUrl + `${id}`, data)
    .then((response) => {
      dispatch({ type: actionTypes.EDIT_INTEREST, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
//Delete Interest
export const deleteInterestRequest = (id, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteInterestUrl + `${id}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_INTEREST, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};

// get all Habits
export const getAllHabits = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllHabitsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_HABITS, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
//Create Habit
export const createHabitRequest = (data, callBack) => (dispatch) => {
  services
    .makePostRequest(authURL.createHabitUrl, data)
    .then((response) => {
      dispatch({ type: actionTypes.CREATE_HABIT, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};
//Edit Habit
export const editHabitRequest = (id, data, callBack) => (dispatch) => {
  services
    .makePatchRequest(authURL.editHabitUrl + `${id}`, data)
    .then((response) => {
      dispatch({ type: actionTypes.EDIT_HABIT, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
//Delete Habit
export const deleteHabitRequest = (id, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteHabitUrl + `${id}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_HABIT, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
//  get all content
export const getAllContents = () => (dispatch) => {
  services
    .makeGetRequest(authURL.getAllContentsUrl)
    .then((response) => {
      dispatch({ type: actionTypes.GET_ALL_CONTENTS, payload: response });
    })
    .catch((error) => {
      toastify("error", error.response?.data?.message);
    });
};
//Create Content
export const createContentRequest = (data, callBack) => (dispatch) => {
  var multiPartBody = true;
  services
    .makePostRequest(authURL.createContentUrl, data, multiPartBody)
    .then((response) => {
      dispatch({ type: actionTypes.CREATE_CONTENT, payload: response.data });

      if (response.status) {
        callBack(response);
      }
    })
    .catch((error) => {
      callBack(error);
    });
};
//Edit Content
export const editContentRequest = (id, data, callBack) => (dispatch) => {
  services
    .makePatchRequest(authURL.editContentUrl + `${id}`, data)
    .then((response) => {
      dispatch({ type: actionTypes.EDIT_CONTENT, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
//Delete Content
export const deleteContentRequest = (id, callBack) => (dispatch) => {
  services
    .makeDeleteRequest(authURL.deleteContentUrl + `${id}`)
    .then((response) => {
      dispatch({ type: actionTypes.DELETE_CONTENT, payload: response.data });

      if (response.status) {
        callBack(response);
      } else {
        console.log("error", response);
      }
    })
    .catch((error) => {
      console.log("error", error.response.data.message);

      toastify("error", error.response.data.message);
    });
};
