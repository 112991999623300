import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import * as authActions from "../../redux/actions/authActions";
import {
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = (e) => {
    setLoading(true);

    if (email?.length) {
      dispatch(
        authActions.forgetPasswordRequest(
          { email: email },
          callBackForForgetPassword
        )
      );
    } else {
      setLoading(false);

      toastify("error", "Kindly enter email");
    }

    e.preventDefault();
  };

  const callBackForForgetPassword = (e) => {
    setLoading(false);
    if (e?.status) {
      toastify("success", "OTP sent successfully");
      setTimeout(() => {
        navigate("/OTP-verification");
      }, 2000);
    } else {
      toastify("error", e.response?.data.message);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="bg-gray-300 min-h-screen flex items-center justify-center rounded">
        <>
          <form
            className="bg-white p-8 rounded-2xl border border-gray-300 w-[500px]"
            onSubmit={onSubmitHandler}
            autoComplete="off"
          >
            <div className="text-center mb-4">
              <h3 className="text-4xl font-bold mb-4">Forget Password</h3>
              <Typography className="text-gray-500 text-base px-6">
                Enter your email to get the reset password link
              </Typography>
            </div>

            <div className="my-4">
              <div className="mb-4">
                <FormControl className="w-full">
                  <TextField
                    type="email"
                    value={email}
                    size="small"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </div>

              <Typography
                className="text-right text-[#0360B2] "
                sx={{
                  fontWeight: "bold !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Return to Login
              </Typography>
            </div>

            <div className="flex justify-end">
              {loading ? (
                <Button variant="contained" disabled>
                  <CircularProgress size={24} thickness={5} />
                </Button>
              ) : (
                <Button type="submit" variant="contained">
                  Send
                </Button>
              )}
            </div>
          </form>
        </>
      </div>
    </>
  );
};

export default ForgetPassword;
