import { Typography, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { getDashboardDataRequest } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.allDashboardData);

  useEffect(() => {
    dispatch(getDashboardDataRequest());
  }, [dispatch]);

  return (
    <div>
      <Typography className="font-bold px-10 mt-3" style={{ fontSize: "2rem" }}>
        Dashboard
      </Typography>

      <Grid container spacing={2} className="px-10 mt-4">
        {dashboardData && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="font-bold"
                  >
                    Total Users
                  </Typography>
                  <Typography variant="h4" component="div" className="mt-2">
                    {dashboardData.total_users}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="font-bold"
                  >
                    Free Users
                  </Typography>
                  <Typography variant="h4" component="div" className="mt-2">
                    {dashboardData.free_users}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="font-bold"
                  >
                    Paid Users
                  </Typography>
                  <Typography variant="h4" component="div" className="mt-2">
                    {dashboardData.paid_users}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="font-bold"
                  >
                    Organizations
                  </Typography>
                  <Typography variant="h4" component="div" className="mt-2">
                    {dashboardData.organizations}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    className="font-bold"
                  >
                    Habits
                  </Typography>
                  <Typography variant="h4" component="div" className="mt-2">
                    {dashboardData.habits}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Dashboard;
