import { Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../redux/actions/authActions";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeAuthToken = () => {
    dispatch(removeToken());
    navigate("/");
  };
  return (
    <div className="sidebar-outer">
      <div className="main-sidebar bg-[#ABC667] min-h-[907px] w-[100%] text-white  rounded-r-md px-4">
        <div className="sidebar-menu font-bold">
          <ul className="list-style-type-none">
            <li>
              <Typography
                onClick={() => navigate("/dashboard")}
                className="nav-link cursor-pointer pt-8 text-2xl"
                id="sidebar-dashboard"
              >
                Dashboard
              </Typography>
            </li>
            <li>
              <Typography
                onClick={() => navigate("/manage-users")}
                className="nav-link cursor-pointer pt-4 text-2xl"
                id="sidebar-user-management"
              >
                User Management
              </Typography>
            </li>
            <li>
              <Typography
                onClick={() => navigate("/manage-contents")}
                className="nav-link cursor-pointer pt-4 text-2xl"
                id="sidebar-content-management"
              >
                Content Management
              </Typography>
            </li>
            <li>
              <Typography
                onClick={() => navigate("/manage-habits")}
                className="nav-link cursor-pointer pt-4 text-2xl"
                id="sidebar-habit-management"
              >
                Habit Management
              </Typography>
            </li>
            <li>
              <Typography
                onClick={() => navigate("/manage-interests")}
                className="nav-link cursor-pointer pt-4 text-2xl"
                id="sidebar-focus-management"
              >
                Interests Management
              </Typography>
            </li>
            <li>
              <Typography
                className="nav-link cursor-pointer pt-4 text-2xl pb-4"
                id="sidebar-logout"
                onClick={removeAuthToken}
              >
                Logout
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
