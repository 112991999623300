// AuthRoutes.js
import React from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";

import Login from "../modules/pages/Login";
import ForgetPassword from "../modules/pages/ForgetPassword";
import ResetPassword from "../modules/pages/ResetPassword";
import PageNotFound from "../modules/pages/PageNotFound";
import OTPVerification from "../modules/pages/OTPVerification";
const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/otp-verification" element={<OTPVerification />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;