import React from "react";


const Layout = ({ children }) => {
  return (
    <>
      {/* <Navbar />
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>

        <Grid item>
        <Router>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/manage-users" element={<ManageUsers />} />
        <Route path="/manage-habits" element={<ManageHabits />} />
        <Route path="/manage-contents" element={<ManageContents />} />
        <Route path="/manage-interests" element={<ManageInterests />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Router>
        </Grid>
      </Grid> */}
    </>
  );
};

export default Layout;
