import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserRequest,
  deleteUserRequest,
  editUserRequest,
  getAllUsers,
} from "../../redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
// import { makeStyles } from "@mui/styles";
// const useStyles = makeStyles({
//   hoverEffect: {
//     transition: "background-color 0.3s",
//     "&:hover": {
//       backgroundColor: "#f2f2f2",
//       cursor: "pointer",
//     },
//   },
// });
const ManageUsers = () => {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.allUserData);
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [createUserDetails, setCreateUserDetails] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [editedUserDetails, setEditedUserDetails] = useState({
    name: selectedUser?.name,
    email: selectedUser?.email,
  });
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const handleDetailsClick = () => {
    setIsDetailsModalOpen(true);
    setSelectedUserDetails(selectedUser);
    setMenuAnchor(null);
  };

  const handleCloseMediumModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedUserDetails(null);
  };
  // const classes = useStyles();
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = (user) => {
    setMenuAnchor(null);
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setSelectedUser(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedUser(null);
    setEditedUserDetails(null);
  };
  const handleMenuOpen = (event, user) => {
    setMenuAnchor(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCreateUser = () => {
    dispatch(createUserRequest(createUserDetails, callBackForCreateUser));
  };
  const callBackForCreateUser = (e) => {
    if (e.status) {
      dispatch(getAllUsers());
      setCreateUserDetails({});
      setSelectedUser(null);
      handleCloseCreateModal();
    } else {
      toastify("error", e.response.data.message);
    }
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    setEditedUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleUpdateUser = () => {
    if (editedUserDetails) {
      const data = {
        name: editedUserDetails?.name
          ? editedUserDetails?.name
          : selectedUser?.name,
        email: editedUserDetails?.email
          ? editedUserDetails?.email
          : selectedUser?.email,
      };
      dispatch(editUserRequest(selectedUser?._id, data, callBackForDeleteUser));
    }
  };

  const handleDeleteClick = (user) => {
    setMenuAnchor(null);
    dispatch(deleteUserRequest(selectedUser._id, callBackForDeleteUser));
  };
  const callBackForDeleteUser = () => {
    setOpenEditModal(false);
    dispatch(getAllUsers());
  };

  return (
    <>
      <ToastContainer />
      <Modal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        aria-labelledby="modal-create-title"
        aria-describedby="modal-create-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Create User</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={createUserDetails.name}
              onChange={handleInputChange}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              value={createUserDetails.email}
              onChange={handleInputChange}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="text"
              fullWidth
              margin="normal"
              name="password"
              value={createUserDetails.password}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateUser}
            >
              Create
            </Button>
            <Button onClick={handleCloseCreateModal}>Cancel</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-edit-title"
        aria-describedby="modal-edit-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Edit User</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              defaultValue={selectedUser?.name}
              onChange={(e) => handleEditInputChange(e)}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              defaultValue={selectedUser?.email}
              onChange={(e) => handleEditInputChange(e)}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateUser}
            >
              Update
            </Button>
            <Button onClick={handleCloseEditModal}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="medium-modal-title"
        aria-describedby="medium-modal-description"
        open={isDetailsModalOpen}
        onClose={handleCloseMediumModal}
        closeAfterTransition
      >
        <div className="modal-container modal-container-medium bg-white p-6 w-[50rem] mx-auto mt-20 rounded-lg">
          {/* Render user details in the medium modal */}
          {selectedUserDetails && (
            <div className="">
              <h2 className="text-2xl font-bold mb-4 text-center">
                User Details
              </h2>
              <div className="border-b border-gray-300 my-4 mx-auto w-[70%]"></div>

              <p className="mb-2 text-xl">
                <span className="font-bold">Name:</span>{" "}
                {selectedUserDetails.name}
              </p>
              <p className="mb-4 text-xl">
                <span className="font-bold">Email:</span>{" "}
                {selectedUserDetails.email}
              </p>
              <p className="mb-4 text-xl">
                <span className="font-bold">Role:</span>{" "}
                {selectedUserDetails.role}
              </p>
              <p className="mb-4 text-xl">
                <span className="font-bold">Type:</span>{" "}
                {selectedUserDetails.role === 1
                  ? "Individual"
                  : selectedUserDetails.role === 2
                  ? "Organization"
                  : selectedUserDetails.role === 3
                  ? "Employee"
                  : "none"}
              </p>
              <p className="mb-4 text-xl">
                <span className="font-bold">Habit awards:</span> 24
              </p>
              <p className="mb-4 text-xl">
                <span className="font-bold">Streak awards:</span> 8
              </p>
            </div>
          )}
        </div>
      </Modal>
      <div className="flex justify-between items-center px-10 mt-3 mb-5">
        <Typography className="font-bold" style={{ fontSize: "2rem" }}>
          Manage Users
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateModal}
        >
          Add User
        </Button>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "90%",
          margin: "auto",
          overflowY: "auto",
          maxHeight: "600px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: "10",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers?.data?.map((user) => (
              <TableRow key={user._id}>
                <TableCell component="th" scope="row">
                  {user.name}
                </TableCell>
                <TableCell align="center">{user.email}</TableCell>
                <TableCell align="center">{user.role}</TableCell>
                <TableCell align="center">
                  {user.role === 1
                    ? "Individual"
                    : user.role === 2
                    ? "Organization"
                    : user.role === 3
                    ? "Employee"
                    : "none"}
                </TableCell>

                <TableCell align="right">
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, user)}
                    size="small"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOpenEditModal(user)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteClick(user)}>
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => handleDetailsClick(user)}>
                      Details
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ManageUsers;
