import * as actionTypes from "../actions/actionType";

const initialState = {
  allContents: [],
};

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CONTENTS:
      return {
        ...state,
        allContents: action.payload,
      };
    case actionTypes.CREATE_CONTENT:
      return {
        ...state,
        newContent: action.payload,
      };

    default:
      return state;
  }
};
