import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Modal,
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createInterestRequest,
  deleteInterestRequest,
  editInterestRequest,
  getAllInterests,
} from "../../redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import moment from "moment";
const ManageInterests = () => {
  const dispatch = useDispatch();
  const { allInterest } = useSelector((state) => state.allInterestData);
  useEffect(() => {
    dispatch(getAllInterests());
  }, [dispatch]);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [createInterestDetails, setCreateInterestDetails] = useState({
    name: "",
  });
  const [editedInterestDetails, setEditedInterestDetails] = useState({
    name: "",
  });
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = (user) => {
    setMenuAnchor(null);
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setSelectedInterest(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedInterest(null);
    setEditedInterestDetails(null);
  };
  const handleMenuOpen = (event, user) => {
    setMenuAnchor(event.currentTarget);
    setSelectedInterest(user);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateInterestDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCreateInterest = () => {
    dispatch(
      createInterestRequest(createInterestDetails, callBackForCreateUser)
    );
  };
  const callBackForCreateUser = (e) => {
    if (e.status) {
      dispatch(getAllInterests());
      setCreateInterestDetails({});
      setSelectedInterest(null);
      handleCloseCreateModal();
    } else {
      toastify("error", e.response.data.message);
    }
  };
  const handleEditInputChange = (e) => {
    setEditedInterestDetails({
      ...editedInterestDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdateInterest = () => {
    if (editedInterestDetails) {
      dispatch(
        editInterestRequest(
          selectedInterest?._id,
          editedInterestDetails,
          callBackForDeleteUser
        )
      );
    }
  };

  const handleDeleteClick = (user) => {
    setMenuAnchor(null);
    dispatch(
      deleteInterestRequest(selectedInterest._id, callBackForDeleteUser)
    );
  };
  const callBackForDeleteUser = () => {
    dispatch(getAllInterests());
  };

  return (
    <>
      <ToastContainer />

      <Modal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        aria-labelledby="modal-create-title"
        aria-describedby="modal-create-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Create Interest</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={createInterestDetails.name}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateInterest}
            >
              Create
            </Button>
            <Button onClick={handleCloseCreateModal}>Cancel</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-edit-title"
        aria-describedby="modal-edit-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Edit Interest</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              defaultValue={selectedInterest?.name}
              onChange={(e) => handleEditInputChange(e)}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateInterest}
            >
              Update
            </Button>
            <Button onClick={handleCloseEditModal}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <div className="flex justify-between items-center px-10 mt-3 mb-5">
        <Typography className="font-bold" style={{ fontSize: "2rem" }}>
          Manage Interest
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateModal}
        >
          Add Interest
        </Button>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "90%",
          margin: "auto",
          overflowY: "auto",
          maxHeight: "600px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: "10",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Created </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allInterest?.data?.map((interest) => (
              <TableRow key={interest._id}>
                <TableCell component="th" scope="row">
                  {interest.name}
                </TableCell>
                <TableCell align="center">
                  {moment(interest.createdAt).format("MMMM Do YYYY")}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, interest)}
                    size="small"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOpenEditModal(interest)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteClick(interest)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ManageInterests;
