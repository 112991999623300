import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Modal,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createHabitRequest,
  deleteHabitRequest,
  editHabitRequest,
  getAllHabits,
  getAllInterests,
} from "../../redux/actions/authActions";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import { create } from "yup/lib/Reference";
const ManageHabits = () => {
  const dispatch = useDispatch();
  const { allHabits } = useSelector((state) => state.allHabitData);
  const { allInterest } = useSelector((state) => state.allInterestData);
  useEffect(() => {
    dispatch(getAllHabits());
    dispatch(getAllInterests());
  }, []);
  console.log("asasas", allInterest);

  const [selectedHabit, setSelectedHabit] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [createHabitDetails, setCreateHabitDetails] = useState({
    name: "",
    description: "",
    interest: "",
    frequency: "",
  });
  const [editedHabitDetails, setEditedHabitDetails] = useState({
    name: "",
    description: "",
    interest: "",
    frequency: "",
  });
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = (Habit) => {
    setMenuAnchor(null);
    setOpenEditModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    setSelectedHabit(null);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedHabit(null);
    setEditedHabitDetails(null);
  };
  const handleMenuOpen = (event, Habit) => {
    setMenuAnchor(event.currentTarget);
    setSelectedHabit(Habit);
    console.log("selected", Habit);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateHabitDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCreateHabit = () => {
    if (
      createHabitDetails?.name &&
      createHabitDetails?.description &&
      createHabitDetails?.interest &&
      createHabitDetails?.frequency
    ) {
      dispatch(createHabitRequest(createHabitDetails, callBackForCreateHabit));
    } else {
      toastify("error", "Kindly fill all the fields");
    }
  };
  const callBackForCreateHabit = (e) => {
    if (e.status) {
      dispatch(getAllHabits());
      setCreateHabitDetails({});
      setSelectedHabit(null);
      handleCloseCreateModal();
    } else {
      toastify("error", e.response.data.message);
    }
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    setEditedHabitDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleUpdateHabit = () => {
    console.log("adad", editedHabitDetails);
    console.log("adad", selectedHabit);

    if (editedHabitDetails) {
      const data = {
        name: editedHabitDetails?.name
          ? editedHabitDetails?.name
          : selectedHabit?.name,
        description: editedHabitDetails?.description
          ? editedHabitDetails?.description
          : selectedHabit?.description,
        frequency: editedHabitDetails?.frequency
          ? editedHabitDetails?.frequency
          : selectedHabit?.frequency,
        interest_id: editedHabitDetails?.interest
          ? editedHabitDetails?.interest
          : selectedHabit?.interest_id,
      };
      dispatch(
        editHabitRequest(selectedHabit?._id, data, callBackForDeleteHabit)
      );
    } else {
      toastify("error", "Kindly fill all the fields");
    }
  };

  const handleDeleteClick = (Habit) => {
    setMenuAnchor(null);
    dispatch(deleteHabitRequest(selectedHabit._id, callBackForDeleteHabit));
  };
  const callBackForDeleteHabit = () => {
    setOpenEditModal(false);

    dispatch(getAllHabits());
  };

  return (
    <>
      <ToastContainer />
      <Modal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        aria-labelledby="modal-create-title"
        aria-describedby="modal-create-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h3>Create Habit</h3>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              value={createHabitDetails.name}
              onChange={handleInputChange}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              value={createHabitDetails.email}
              onChange={handleInputChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="interest-id-label">Interest </InputLabel>
              <Select
                labelId="interest-id-label"
                id="interest-id"
                name="interest"
                value={createHabitDetails.interest}
                onChange={handleInputChange}
                label="Interest ID"
              >
                {allInterest?.data.map((interest) => (
                  <MenuItem key={interest._id} value={interest._id}>
                    {interest.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Frequency"
              variant="outlined"
              type="text"
              fullWidth
              margin="normal"
              name="frequency"
              value={createHabitDetails.password}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateHabit}
            >
              Create
            </Button>
            <Button onClick={handleCloseCreateModal}>Cancel</Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-edit-title"
        aria-describedby="modal-edit-description"
      >
        <div className="modal-container bg-white p-6 w-96 mx-auto mt-20 rounded-lg">
          <h2>Edit Habit</h2>
          <div>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="name"
              defaultValue={selectedHabit?.name}
              onChange={(e) => handleEditInputChange(e)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              defaultValue={selectedHabit?.description}
              onChange={(e) => handleEditInputChange(e)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="interest-id-label">Interest </InputLabel>
              <Select
                labelId="interest-id-label"
                id="interest-id"
                name="interest"
                defaultValue={selectedHabit?.interest}
                onChange={handleInputChange}
                label="Interest ID"
              >
                {allInterest?.data.map((interest) => (
                  <MenuItem key={interest._id} value={interest._id}>
                    {interest.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Frequency"
              variant="outlined"
              fullWidth
              margin="normal"
              name="frequency"
              defaultValue={selectedHabit?.frequency}
              onChange={(e) => handleEditInputChange(e)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateHabit}
            >
              Update
            </Button>
            <Button onClick={handleCloseEditModal}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <div className="flex justify-between items-center px-10 mt-3 mb-5">
        <Typography className="font-bold" style={{ fontSize: "2rem" }}>
          Manage Habits
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateModal}
        >
          Add Habit
        </Button>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "90%",
          margin: "auto",
          overflowY: "auto",
          maxHeight: "600px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: "10",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Frequency</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allHabits?.data?.map((habit) => (
              <TableRow key={habit._id}>
                <TableCell component="th" scope="row">
                  {habit.name}
                </TableCell>
                <TableCell align="center">{habit.frequency}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={(event) => handleMenuOpen(event, habit)}
                    size="small"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOpenEditModal(habit)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteClick(habit)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ManageHabits;
