// InnerRoutes.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "../modules/pages/Dashboard";
import ManageHabits from "../modules/pages/ManageHabits";
import ManageUsers from "../modules/pages/ManageUsers";
import ManageContents from "../modules/pages/ManageContents";
import PageNotFound from "../modules/pages/PageNotFound";
import ManageInterests from "../modules/pages/ManageInterests";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Grid } from "@mui/material";

const InnerRoutes = () => {
  return (
    <>
      <Navbar />
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>

        <Grid item xs={10}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/manage-habits" element={<ManageHabits />} />
            <Route path="/manage-contents" element={<ManageContents />} />
            <Route path="/manage-interests" element={<ManageInterests />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Grid>
      </Grid>
    </>
  );
};

export default InnerRoutes;
