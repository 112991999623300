import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dashboardReducer } from "./dashboardReducer";

import { manageUserReducer } from "./manageUsersReducer";
import { interestReducer } from "./interesetReducer";
import { habitReducer } from "./habitReducer";
import { contentReducer } from "./contentReducer";

const appReducer = combineReducers({
  authentication: authReducer,
  allDashboardData: dashboardReducer,
  allUserData: manageUserReducer,
  allInterestData: interestReducer,
  allHabitData: habitReducer,
  allContentData: contentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
