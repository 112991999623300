import * as actionTypes from "../actions/actionType";

const initialState = {
  allInterest: [],
};

export const interestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_INTERESTS:
      return {
        ...state,
        allInterest: action.payload,
      };
    case actionTypes.CREATE_INTEREST:
      return {
        ...state,
        newInterest: action.payload,
      };

    default:
      return state;
  }
};
