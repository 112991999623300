import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
// import * as authActions from "../../redux/actions/authActions";
import {
  FormControl,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPasswordRequest } from "../../redux/actions/authActions";
// import { resetPasswordRequest } from "../../redux/actions/authActions";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { passwordResetRequestData } = useSelector(
    (state) => state?.authentication
  );

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [finalPassword, setFinalPassword] = useState(" ");

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    setNewPassword(newPassword);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword === confirmPassword) {
      setFinalPassword(newPassword);
      const data = {
        password: newPassword,
        id: passwordResetRequestData._id,
      };

      dispatch(resetPasswordRequest(data, callBack));
    } else {
      toastify("error", "passwords are not same");

      setLoading(false);
    }
  };

  const callBack = (response) => {
    if (response.status) {
      toastify("success", response.message);

      setTimeout(() => {
        setLoading(false);

        navigate("/");
      }, 2000);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="bg-gray-300 min-h-screen flex items-center justify-center rounded">
        <>
          <form
            className="bg-white p-8 rounded-2xl border border-gray-300 w-[500px]"
            onSubmit={onSubmitHandler}
            autoComplete="off"
          >
            <div className="text-center mb-4">
              <h3 className="text-4xl font-bold mb-4">Reset Password</h3>
              <Typography className="text-gray-500 text-base px-6">
                You can reset your password from here
              </Typography>
            </div>

            <div className="my-4">
              <div className="my-4">
                <div className="form-group login-form mt-1 mb-2">
                  <FormControl className="w-full px-2 h-50 font-gotham">
                    <TextField
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      size="small"
                      placeholder="Password*"
                      onChange={handlePasswordChange}
                      className="bg-white"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleNewPasswordVisibility}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
                <div className="form-group login-form mb-0">
                  <FormControl className="w-full px-2 h-50 font-gotham">
                    <TextField
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      size="small"
                      placeholder="Confirm Password*"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="bg-white"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleConfirmPasswordVisibility}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <Typography
                className="text-right text-[#0360B2] "
                sx={{
                  fontWeight: "bold !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Return to Login
              </Typography>
            </div>

            <div className="flex justify-end">
              {loading ? (
                <Button variant="contained" disabled>
                  <CircularProgress size={24} thickness={5} />
                </Button>
              ) : (
                <Button type="submit" variant="contained">
                  Change
                </Button>
              )}
            </div>
          </form>
        </>
      </div>
    </>
  );
};

export default ResetPassword;
