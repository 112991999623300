import React, { useEffect } from "react";
import "./index.css";
import InnerRoutes from "./navigation/InnerRoutes";
import AuthRoutes from "./navigation/AuthRoutes";
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "./redux/actions/authActions";

const App = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(removeToken());
  // }, []);

  const isTokenAvailable = useSelector((state) => state.authentication?.token);

  console.log("token", isTokenAvailable);

  return isTokenAvailable ? (
    <BrowserRouter>
      <InnerRoutes />
    </BrowserRouter>
  ) : (
    <BrowserRouter>
     <AuthRoutes />
  </BrowserRouter>
   
  );
};

export default App;
