import * as actionTypes from "../actions/actionType";

const initialState = {
  dashboardData: [],
  token: "",
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
        // token: action.payload.token,
      };

    default:
      return state;
  }
};
