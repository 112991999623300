import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger'; 
import rootReducer from '../reducers/index.js';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const loggerMiddleware = createLogger();

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunk, loggerMiddleware];

const store = createStore(
    persistedReducer,
    applyMiddleware(...middlewares)
);

const persistor = persistStore(store);

export {
    store, 
    persistor
}